import React from 'react';
import ProductDetailsLayout from '../components/ProductDetails/index';
import { graphql } from 'gatsby';
import { modifyContent } from '../utils/helpers';

// If meta will be different for all the product pages then need to create different query for all else keep it same

export const query = graphql`
        query{
            meta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: {
                        in:[ "#banking" ]
                        eq: "#meta"
                    }}}
                }
            ){
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
            banner: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#banking" ]
                        eq: "#banner"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            productFeedback: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#banking" ]
                        eq: "#product-feedback"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            productList: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        eq: "#product-list",
                        ne:"#homepage"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            stepsCarousel: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#banking" ]
                        eq: "#steps-carousel"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            curve: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#curve-design"}}}
                }
            ) {
                nodes {
                    plaintext
                }
            }
        }
    `;

const BankingPage = props => {

    const data = modifyContent(props.data);

    return (
        <ProductDetailsLayout
            data={data}
            location={props.location}
            title={props.data.meta.nodes[0].meta_title}
            description={props.data.meta.nodes[0].meta_description}
        />
    );
}

export default BankingPage;
